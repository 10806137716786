import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { classnames, classname, raw, createImage } from 'utils';

import './Card.css';

/**
 * Шаблон карточки
 */
class Card extends Component {
  cl = classname('card');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Заголовок */
    title: PropTypes.string.isRequired,
    /** Ссылка */
    url: PropTypes.string,
    /** Тип */
    type: PropTypes.string.isRequired,
    /** Тип */
    label: PropTypes.string,
    /** Тема */
    theme: PropTypes.oneOf(['light', 'dark', 'green']),
    /** Менять тему при ховере */
    changeThemeOnHover: PropTypes.bool,
    /** Показывать фон при наведении */
    showBgOnHover: PropTypes.bool,
    /** Изображение */
    image: PropTypes.string,
    /** Видео */
    video: PropTypes.arrayOf(PropTypes.string),
    /** Контент */
    children: PropTypes.node,
    /** Дополнить шапку */
    childrenHead: PropTypes.node
  };

  static defaultProps = {
    theme: 'light'
  };

  constructor(props) {
    super(props);
    this.state = {
      video: props.video
    };
  }

  componentDidMount() {
    if (process.browser && this.video) {
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.play();
        } else {
          this.video.pause();
        }
      });

      this.observer.observe(this.video);
    }
  }

  play() {
    this.video.play().catch((err) => setTimeout(() => this.play(), 1000));
  }

  componentWillUnmount() {
    if (process.browser && this.video) {
      this.observer.disconnect();
      this.setState({ video: [] });
      this.video.load();
    }
  }

  render() {
    const { className, url, theme, type, label, childrenHead, title, children, image, tags, button, onClick } =
      this.props;

    const { video } = this.state;

    const classes = classnames(className, this.cl({ theme, interactive: Boolean(url) }));
    const internal = /https?:\/\//.test(url);

    const Tag = url ? (!internal ? Link : 'a') : button ? 'button' : 'div';
    const props = { className: classes };

    if (url) {
      props['data-hover'] = '';

      if (!internal) {
        props.to = url;
      } else {
        props.href = url;
        props.target = '_blank';
      }
    }

    if (button) {
      props.type = 'button';
      props.onClick = onClick;
    }

    return (
      <Tag {...props}>
        <div className={this.cl('inner')}>
          <div className={this.cl('head')}>
            <div className={this.cl('type')} {...raw(type)} />
            {label && <div className={this.cl('label')} {...raw(label)} />}
            {childrenHead && <div className={this.cl('append')}>{childrenHead}</div>}
          </div>
          <div className={this.cl('title')} {...raw(title)} />
          {children && <div className={this.cl('content')}>{children}</div>}
        </div>
        {image && !video && (
          <img
            className={classnames(this.cl('bg'))}
            src={createImage(image, 586 * 2, 556 * 2)}
            srcSet={`${createImage(image, 586 * 2, 556 * 2)} 2x, ${createImage(image, 586 * 3, 556 * 3)} 3x`}
            sizes="(min-width: 1200px) 586px, 100vw"
            loading="lazy"
            alt={title}
          />
        )}
        {video && (
          <video
            className={classnames(this.cl('bg'))}
            // src={'https://static.nimax.ru' + video[0]}
            muted={true}
            loop={true}
            playsInline={true}
            ref={(node) => (this.video = node)}
            loading={'lazy'}
            autoPlay={true}
          >
            {video.map((item) => (
              <source key={item} src={'https://static.nimax.ru' + item} type={'video/' + item.split('.').pop()} />
            ))}
          </video>
        )}
      </Tag>
    );
  }
}

export default Card;
